.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

body,
html {
  font-family: "sf-pro-display", "Roboto", sans-serif;
}

.ant-card {
  /* filter: drop-shadow(0 1px 1px); */
  /* box-shadow: 0px 2px 4px gray, -1px 2px 4px gray, 1px 2px 4px gray; */
  /* box-shadow: 0 0 8px 2px #888; line shadow */
  box-shadow: 0px 5px 20px -19px, 2px 0px 20px -19px, -2px 0px 20px -19px;
}

.add-shadow {
  box-shadow: 0px 5px 20px -19px, 2px 0px 20px -19px, -2px 0px 20px -19px;
}

.app-height {
  /* height: 93vh; */
}

.shk-sam-bottom-nav-active a {
  text-decoration: none;
  font-weight: bold;
}

.shk-sam-bottom-nav-active .active div {
  color: #2392FF !important;
}

.shk-sam-bottom-nav-active .active svg path,
.shk-sam-bottom-nav-active .active svg circle,
.shk-sam-bottom-nav-active .active svg rect {
  stroke: #2392FF !important;
}